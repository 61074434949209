<template>
    <div>
        <Table 
        :table="table"
        @fetch-data="fetchData" 
        @reject="rejectRequest" 
        @search-data="initialize" 
        @approve="approveRequest" />
    </div>
</template>

<script>
import Table from "@/components/core/TableRequest"
import network from '@/core/network'
export default {
    components: {
        Table
    },
    data() {
        return {
            searchData: {
                message: '',
                selected: ['0','1']
            },
            table: {
                page: 1,
                itemsPerPage: 10,
                totalItems: 10,
                desserts: [],
                headers: [
                    {
                        text: 'Tên người dùng',
                        value: 'name',
                        sortable: false
                    },
                    {
                        text: 'Tên Admin',
                        value: 'name_admin',
                        sortable: false
                    },
                    {
                        text: 'Số tiền',
                        value: 'amount',
                        sortable: false
                    },
                    {
                        text: 'Tạo lúc',
                        value: 'created_at',
                        sortable: false
                    },
                    {
                        text: 'Trạng thái',
                        value: 'type',
                        sortable: false
                    },
                    // {
                    //     text: 'Thao tác', 
                    //     value: 'actions', 
                    //     sortable: false, 
                    //     width: "100px" 
                    // },
                ],
            }
        }
    },
    mounted() {
        this.initialize();
    },
    methods: {
        fetchData(page) {
            this.page = page;
            this.initialize(this.searchData)
        },
        initialize(searchData) {
            if (searchData) {
                this.searchData = searchData
            }
            network.get('/requests/history-money', {
                name: this.searchData.message ?? '',
                page: this.table.page,
                filter: this.searchData.selected,
                limit: 10
            }).then(res => {
                console.log(res, 1);
                this.table.desserts = res.data.docs
                this.headers[0].value = status === 1 ? 'active' : 'hi';
                this.table.totalItems = res.data.totalDocs
                console.log(this.desserts);
            })
        },
        approveRequest(id) {
            network.post('/requests/action/recharge',
                { 
                    id: id,
                    status: 'approved'
                 }
            ).then(res => {
                if (res.status == 200) {
                    this.$toast.open({
                        message: res.data.message,
                        type: 'success',
                        position: 'top'
                    });
                    this.initialize();
                } else {
                    this.$toast.open({
                        message: res.data.message,
                        type: 'error',
                        position: 'top'
                    });
                }
            }).catch(err => {
                this.$toast.open({
                    message: err,
                    type: 'error',
                    position: 'top'
                });
            });
        },
        rejectRequest(id) {
            network.post('/requests/action/recharge',
                { 
                    id: id,
                    status: 'rejected'
                 }
            ).then(res => {
                console.log(res);
                if (res.status == 200) {
                    this.$toast.open({
                        message: res.data.message,
                        type: 'success',
                        position: 'top'
                    });
                    this.initialize();
                } else {
                    this.$toast.open({
                        message: res.data.message,
                        type: 'error',
                        position: 'top'
                    });
                }
            }).catch(err => {
                this.$toast.open({
                    message: err,
                    type: 'error',
                    position: 'top'
                });
            });
        }
    }

}
</script>

<style></style>